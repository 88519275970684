
.main-slider{
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-container{
    background: white;
    width: 85%;
}

.button {
    background: rgba(0, 0, 0, 0.5);
}

.slider-container img {
    width: 30%;
    height: 100%;
    object-fit: cover;
}

.card-slider{
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    position: relative;
}

.card-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.name {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-top: 10px;
    position: absolute;
    bottom: -3px;
    background: #d12934;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    height: 54px;
    width: calc(100% - 10px);
    margin-left: 5px;

}

.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 100%;
    height:100%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
}


#bannerContent {
    background: rgba(255, 255, 255, 0.818);
    padding: 30px 20px;
    position: relative;
    z-index: 1; /* s'assurer que le contenu est au-dessus de l'arrière-plan */
}




/* ----------------- */


.title-banner {
}

.date-info {
    font-weight: bold;
    font-size: 1.5rem;
}

.events-content strong{
    font-weight: bold;
    color: #D12934;

}

.title-slider{
    white-space: nowrap;
    font-size: 1.2rem!important;
}

.container-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 2vh;
}

.container-cards div {
    padding-bottom: 10px;
}

.link-participant{
    display: flex;
    gap: 10px;
}

@media (max-width: 768px) {
    .container-cards{
        justify-content: center;
    }
}