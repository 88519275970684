.selected {
    background-color: #D12834;
    color: white ;
}

.details-selected {
    border-left: 8px solid #D12834;
    color: black ;
}

.thematique  .marked  u {
    color: black;
}

.selected .marked  u {
    background-color: #D12834;
    color: white ;
}